
import { Vue, Component } from 'vue-property-decorator';
import HomepageDetail from '@/components/homepage/detail.vue';

@Component({
  name: 'StartupDetail',
  components: {
    HomepageDetail,
  },
})
export default class extends Vue {
}
