import request from '@/utils/request';

const PATH = '/interest-company';

export const getMyInterestCompanyList = () => request({
  url: PATH,
  method: 'get',
});

export const getBookmarkList = (params: any) => request({
  url: `${PATH}/bookmark`,
  method: 'get',
  params,
});

export const getInterestCompanyList = (nickname: string) => request({
  url: `${PATH}/${nickname}`,
  method: 'get',
});

export const addInterestCompany = (interestCompanyUid: string) => request({
  url: `${PATH}/${interestCompanyUid}`,
  method: 'post',
});

export const deleteInterestCompany = (interestCompanyUid: string) => request({
  url: `${PATH}/${interestCompanyUid}`,
  method: 'delete',
});
