
import { addInterestCompany, deleteInterestCompany, getMyInterestCompanyList } from '@/api/interestCompany';
import { getHomepageInfo } from '@/api/myHomepage';
import { getBoardList } from '@/api/myHomepageBoard';
import { getHomepagePostList } from '@/api/myHomepagePost';
import { UserModule } from '@/store/user';
import { IMyHomepageList, IMyHomepagePost } from '@/types';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'HomepageDetail',
})
export default class extends Vue {
  mounted() {
    this.getHomepageInfo();
  }

  private homepage: IMyHomepageList | null = null;

  private productList: IMyHomepagePost[] = [];

  private productBoardUid = '';

  private interestCompanyList: string[] = [];

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  get isLogined() {
    return UserModule.token;
  }

  private getHomepageInfo() {
    getHomepageInfo(this.$route.params.nickname).then((res) => {
      this.homepage = res.data;
    });
    getBoardList(this.$route.params.nickname).then((res) => {
      if (res.data[1]) {
        this.productBoardUid = res.data[1].uid;
        const listQuery = {
          page: 0,
          size: 4,
          boardUid: res.data[1].uid,
        };
        getHomepagePostList(listQuery).then((response) => {
          this.productList = response.data.content;
        });
      }
    });
    getMyInterestCompanyList().then((res) => {
      this.interestCompanyList = res.data;
    });
  }

  private isInterestCompany(uid: string) {
    return this.interestCompanyList.indexOf(uid) > -1;
  }

  private handleClickInterest(uid: string, follow: boolean) {
    if (follow) {
      addInterestCompany(uid).then(() => {
        this.interestCompanyList.push(uid);
      });
    } else {
      deleteInterestCompany(uid).then(() => {
        const index = this.interestCompanyList.indexOf(uid);
        if (index > -1) this.interestCompanyList.splice(index, 1);
      });
    }
  }

  private getRouterName() {
    if (this.$route.name === 'MakerspaceDetail') return 'Makerspace';
    if (this.$route.name === 'StartupDetail') return 'Startup';
    return '';
  }

  private getUrl(url: string) {
    if (url.startsWith('http') || url.startsWith('https')) return url;
    return `http://${url}`;
  }

  private getGradeFile() {
    if (this.homepage?.user.companyGradeFileUid) return `${process.env.VUE_APP_COMMON_API}/attached-file/${this.homepage.user.companyGradeFileUid}`;
    return '#';
  }
}
