import { IMyHomepagePostAdd } from '@/types';
import request from '@/utils/request';

const PATH = '/my-homepage/post';

export const getHomepagePostList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getHomepageProductList = (listQuery: any) => request({
  url: `${PATH}/product`,
  method: 'get',
  params: {
    ...listQuery,
  },
});

export const getHomepagePost = (postUid: string, pass: string) => request({
  url: `${PATH}/${postUid}`,
  method: 'get',
  params: {
    password: pass,
  },
});

export const addHomepagePost = (data: IMyHomepagePostAdd) => request({
  url: PATH,
  method: 'post',
  data,
});

export const updateHomepagePost = (postUid: string, data: IMyHomepagePostAdd) => request({
  url: `${PATH}/${postUid}`,
  method: 'put',
  data,
});

export const deleteHomepagePost = (uid: string, pass: string) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
  params: {
    password: pass,
  },
});
